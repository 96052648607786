import React, { useEffect } from 'react'
import bakers from '../../assets/bakers.png'
import { AiOutlineInstagram } from 'react-icons/ai'
import AOS from "aos";
import "aos/dist/aos.css";

function Vendors () {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
  return (
    <div id='vendors' className='overflow-x-hidden md:h-[50rem] h-screen flex flex-col items-center justify-center bg-center bg-fixed bg-cover bg-[url(https://img.freepik.com/free-photo/delicious-cake-with-fruits-cream_23-2148972058.jpg?size=626&ext=jpg&uid=R101835304&ga=GA1.2.2025824122.1683209503&semt=sph)]'>
   <div className='flex flex-col gap-4 md:gap-8 items-center justify-center bg-[blue] bg-opacity-50 bg-cover w-screen h-screen md:h-[50rem] overflow-y-scroll'>
    <div className='flex items-center gap-2'>
        <img src={bakers} alt="" className='w-8'/>
        <h1 className='text-4xl md:text-5xl text-white font-bold'>Bakers</h1>
    </div>
    <div data-aos="fade-up" className="flex flex-col items-center justify-center px-2">
      <ul  className='grid grid-cols-2 items-center justify-center md:grid-cols-3 gap-2 md:gap-8'>
        <a href="https://www.instagram.com/lapatisseriedejoie/" className='flex gap-1'>
            <AiOutlineInstagram  className='text-2xl md:text-4xl text-white hover:text-[orange]'/>
            <li className='text-white lg:text-3xl '>La Patisserie de Joie</li>
        </a>
        <a href="https://www.instagram.com/lynns_bakes254/" className='flex gap-1 text-white'>
            <AiOutlineInstagram  className='text-2xl md:text-4xl text-white hover:text-[orange]'/>
            <li className='text-white lg:text-3xl '>Lynn Bakes</li>
        </a>
         <a href="https://www.instagram.com/cakecouturekenya/" className='flex gap-1'>
            <AiOutlineInstagram  className='text-2xl md:text-4xl text-white hover:text-[orange]'/>
            <li className='text-white lg:text-3xl '>Cake Couture Kenya</li>
        </a> 
        <a href="https://www.instagram.com/littlecakegirl.ke/" className='flex gap-1'>
            <AiOutlineInstagram  className='text-2xl md:text-4xl text-white hover:text-[orange]'/>
            <li className='text-white lg:text-3xl '>Little Cake Girl Ltd.</li>
        </a>
         <a href="https://www.instagram.com/mix_and_whisk_bakery/" className='flex gap-1'>
            <AiOutlineInstagram  className='text-2xl md:text-4xl text-white hover:text-[orange]'/>
            <li className='text-white lg:text-3xl '>Mix and Whisk</li>
        </a> 
        <a href="https://www.instagram.com/thetipsybakery/" className='flex gap-1'>
            <AiOutlineInstagram  className='text-2xl md:text-4xl text-white hover:text-[orange]'/>
            <li className='text-white lg:text-3xl '>The Tipsy Bakery</li>
        </a>
         <a href="https://www.instagram.com/keky_tamu/" className='flex gap-1'>
            <AiOutlineInstagram  className='text-2xl md:text-4xl text-white hover:text-[orange]'/>
            <li className='text-white lg:text-3xl '>Keky Tamu</li>
        </a>
        <a href="https://www.instagram.com/tamimastamtam/" className='flex gap-1'>
            <AiOutlineInstagram  className='text-2xl md:text-4xl text-white hover:text-[orange]'/>
            <li className='text-white lg:text-3xl '>Tamima´s Tam Tam</li>
        </a>
        <a href="https://www.instagram.com/ginahsbakes/" className='flex gap-1'>
            <AiOutlineInstagram  className='text-2xl md:text-4xl text-white hover:text-[orange]'/>
            <li className='text-white lg:text-3xl '>Ginah´s Bakes</li>
        </a>
        <a href="https://www.instagram.com/sweetartluxurycakes/" className='flex gap-1'>
            <AiOutlineInstagram  className='text-2xl md:text-4xl text-white hover:text-[orange]'/>
            <li className='text-white lg:text-3xl '>Sweetart Luxury Cakes</li>
        </a>
        <a href="https://www.instagram.com/lavyeen__bakes/" className='flex gap-1'>
            <AiOutlineInstagram  className='text-2xl md:text-4xl text-white hover:text-[orange]'/>
            <li className='text-white lg:text-3xl '>Kui Thuo</li>
        </a>
        <a href="https://www.instagram.com/tajicakeske/" className='flex gap-1'>
            <AiOutlineInstagram  className='text-2xl md:text-4xl text-white hover:text-[orange]'/>
            <li className='text-white lg:text-3xl '>Taji Cake</li>
        </a>
        <a href="https://www.instagram.com/sweetthings_254/" className='flex gap-1'>
            <AiOutlineInstagram  className='text-2xl md:text-4xl text-white hover:text-[orange]'/>
            <li className='text-white lg:text-3xl '>Sweethings254</li>
        </a>
        <a href="https://www.instagram.com/lizbakes254/" className='flex gap-1'>
            <AiOutlineInstagram  className='text-2xl md:text-4xl text-white hover:text-[orange]'/>
            <li className='text-white lg:text-3xl '>Lizbakes254</li>
        </a>
        <a href="https://www.instagram.com/theprincessandthecake/" className='flex gap-1'>
            <AiOutlineInstagram  className='text-2xl md:text-4xl text-white hover:text-[orange]'/>
            <li className='text-white lg:text-3xl '>Princess & the Cake</li>
        </a>

      </ul>
      </div>
      </div>
    </div>
  )
}

export default Vendors
