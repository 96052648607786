const topNav = [
    {
        id: 1, 
        label: 'Home',
        href: '/'
    },
    {
        id: 2, 
        label: 'Resources',
        href: '/resources'
    },
    {
        id: 3, 
        label: 'Vendors',
        href: '/vendors'
    },
    {
        id: 4, 
        label: 'About Us',
        href: '/about'
    },
    
    
];
export const getTopNav = () => {
    return topNav;
};